import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

export default class Actions extends Component {
    render() {
        return (
        < Button variant = "contained" color = "primary" >
                Hello World
        </Button >
        )
    }
}