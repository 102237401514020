import React, { Component } from 'react';
//import symbol from '../extinctionsymbol.svg';
//import Grid from '@material-ui/core/Grid';
import Column from './Column';
//import FuckedButton from './FuckedButton';
//import Event from './Event';
import Demands from './Demands';
//import { Link } from 'react-router-dom'
import logo from '../images/XR-logo-RGB-Black-Linear.png';

export default class Home extends Component {

    render() {
        return (
            // <Grid container spacing={24} alignContent={'center'}>
            //     <Grid item>
            //         test
            //     </Grid>
            //     <Grid item>
            //         test
            //     </Grid>
            // </Grid>
            // <div
            //     style={{
            //         backgroundColor: '#00ae18',
            //         textAlign:'center'
            //     }}
            //     // className="App"
            // >
            <div
                style={{
                    // backgroundColor:'green',
                }}
            >
                <Column>
                    {/* <div
                        style={{
                            textAlign: 'center',
                            fontFamily:'fucked',
                            fontSize:'60px',
                        }}
                    >
                        EXTINCTION REBELLION SEATTLE
                    </div> */}
                    {/* <img src={symbol} className="App-logo" alt="logo" /> */}
                    <img src={logo} className="App-logo" alt="logo" />
                    <br/>
                    <div className={'crimson'}>
                        WE ARE FACING AN UNPRECEDENTED GLOBAL EMERGENCY. THE GOVERNMENT HAS FAILED TO PROTECT US. TO SURVIVE, IT'S GOING TO TAKE EVERYTHING WE'VE GOT.
                    </div>
                    {/* <div
                dangerouslySetInnerHTML={{__html: '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe-b6cruFL0U-ELwuYbPZYnf0iPHc4g9wuAJ3U3yWQSbQhErA/viewform?embedded=true" width="640" height="465" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>'}}
            /> */}

                  <Demands></Demands>
                </Column>

            </div>
            // </div>
        );
    };
};
