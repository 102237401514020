import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
//import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormGroup from '@material-ui/core/FormGroup';
//import MenuItem from '@material-ui/core/MenuItem';
//import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemText from '@material-ui/core/ListItemText';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom'
import FuckedButton from './FuckedButton';
import SocialMedia from './SocialMedia';


const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class MenuAppBar extends React.Component {
  state = {
    auth: true,
    XRanchorEl: null,
    NAVanchorEl: null,
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleXRMenu = event => {
    this.setState({ XRanchorEl: event.currentTarget });
  };

  handleNAVMenu = event => {
    this.setState({ NAVopen: true });
  };

  handleCloseXR = () => {
    this.setState({ XRanchorEl: null });
  };

  handleCloseNAV = () => {
    this.setState({ NAVopen: false });
  };

  render() {
    const { classes } = this.props;
    const { auth, NAVanchorEl} = this.state;
    //const XRopen = Boolean(XRanchorEl);
    const NAVopen = Boolean(NAVanchorEl);

    const sideList = (
        <div className={classes.list}>
          <List>
            <Link key={'home'} to={'/'}>
                <ListItem button >
                  <FuckedButton>
                    <HomeIcon/>Home
                  </FuckedButton>
                </ListItem>
            </Link>
            {['Demands', 'Principles'].map((text, index) => (
                <Link key={text} to={'/' + text.replace(/\s/g, '')}>
                    <ListItem button>
                        <FuckedButton>{text}</FuckedButton>
                    </ListItem>
                </Link>

            ))}
            <a key={'global'} href='https://rebellion.earth/' target="_blank" rel="noopener noreferrer">
                <ListItem button >
                  <FuckedButton>Worldwide</FuckedButton>
                </ListItem>
            </a>
          </List>

          <Divider />

          <SocialMedia></SocialMedia>
          {/* <List>
            {['Who We Are', 'Actions'].map((text, index) => (
              <Link to={'/' + text.replace(/\s/g, '')}>
                <ListItem button key={text}>
                  <FuckedButton>{text}</FuckedButton>
                </ListItem>
             </Link>
            ))}
          </List> */}
        </div>
      );

    return (
      <div className={classes.root}>
        {/* <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={auth} onChange={this.handleChange} aria-label="LoginSwitch" />
            }
            label={auth ? 'Logout' : 'Login'}
          />
        </FormGroup> */}
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                aria-owns={NAVopen ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleNAVMenu}
            >
                <MenuIcon />
                {/* menu */}
            </IconButton>
            {/* <Drawer></Drawer> */}
            <Drawer
                open={this.state.NAVopen}
                onClose={this.handleCloseNAV}
            >
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.handleCloseNAV}
                    onKeyDown={this.handleCloseNAV}
                >
                    {sideList}
                </div>
            </Drawer>
            {/* <SocialMedia/> */}
            {/* <Menu
                id="menu-appbar"
                anchorEl={NAVanchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={NAVopen}
                onClose={this.handleCloseNAV}
            >
                <MenuItem onClick={this.handleCloseNAV}>Demands</MenuItem>
                <MenuItem onClick={this.handleCloseNAV}>Principles</MenuItem>
            </Menu> */}

            {/* <Typography variant="h6" color="inherit" className={classes.grow}>
              Photos
            </Typography> */}
            {auth && (
              <div
                style={{
                  position:'absolute',
                  right:'0px',
                  marginRight:'15px',
                  display: 'flex',
                  alignItems:'center'
                }}
              >
                <SocialMedia></SocialMedia>
                <Link
                  to={'/getinvolved'}
                  style={{
                    marginLeft: '15px'
                  }}
                >
                    <FuckedButton>get involved</FuckedButton>
                </Link>

              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuAppBar);
