import React from 'react';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Column from './Column';
import './Demands.css'
//import Content from '../config/demands.json'
import { withStyles } from '@material-ui/core/styles';
import ContrastPanel from './ContrastPanel'

const ExpansionPanel = withStyles({
    root: {
    //   border: '10px solid rgba(0,0,0,.125)',
    //   boxShadow: 'none',
    //   '&:not(:last-child)': {
    //     borderBottom: 0,
    //   },
      '&:before': {
        display: 'none',
      },
    },
    // expanded: {
    //   margin: 'auto',
    // },
  })(MuiExpansionPanel);


export default class extends React.Component {
    renderPanels = () => {
        return this.props.content.map((label, i) => {
            return (
                <div key={i}>
                    {this.props.enumerate && (
                        <span
                            style={{
                                // fontSize:'8vw',
                                fontFamily:'fucked',
                                color:'white',
                                display:'block',
                                marginTop:'50px'
                                // padding:'15px',

                                // float:'left'
                            }}
                            className={'enumerate'}

                        >
                            {i+1 + (this.props.indexOffset || 0)}.
                        </span>

                    )}
                    <div
                        style={{
                            display:'inline',
                            maxWidth:'80%',
                            maxHeight:400,

                        }}
                    >
                        {this.props.expand ? this.expansionPanel(label) : this.ghost(label)}
                    </div>
                    <br/>
                </div>
                // {/* </li> */}
            )
        })
    }

    renderHeader = () => {
      if (this.props.title) {
        return(
          <h1
              style={{
                  fontFamily:'fucked',
                  fontSize:'10vw',
                  color: 'white',
              }}
          >
              {this.props.title}
          </h1>
        )
      }
    }

    ghost = (label) => (
        <div
            className={'ghost'}
            style={{
                ...this.props.style,
                // color:'white'
            }}
        >{label.x}</div>
    )

    expansionPanel = (label) => (
        <ExpansionPanel
            style={{
                backgroundColor:'rgba(255, 255, 255, 0.69)',
                maxWidth:'700px',
                margin: '0 auto',
                marginBottom: '50px',
                // opacity: 0.1,
            }}F
        >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {/* <Typography>Expansion Panel 1</Typography> */}
                <span
                    style={{
                        fontFamily:'crimson_regular',
                        fontWeight:'600',
                        fontSize:'34px',
                        textAlign: 'left',
                        // color:'white'
                    }}
                >
                    {label.x}
                </span>

            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ContrastPanel>
                    {label.y}
                </ContrastPanel>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )

    render() {
        //const descriptions =['hahaha']
        return (
            <>
                <div
                    style={{
                        // height:'3000 px',
                        backgroundImage: `url(${this.props.imgSrc})`,
                        backgroundAttachment: 'fixed',
                        // backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                {/* <img
                    src={this.props.imgSrc}
                    style={{
                        position: 'fixed',
                        zIndex: -1,
                        maxWidth:"100%",
                    }}
                /> */}
                <Column>

                    <div
                        style={{
                            // textAlign:'center',
                            maxWidth:1000,
                        }}
                    >
                        {this.renderHeader()}
                        {this.renderPanels()}
                    </div>
                </Column>
                </div>
            </>
        )

    }
}
