import React from 'react';

export default (props) => (
    <div
        style={{

            //minHeight: '90vh',
            minWidth: 'calc(100vw - 15px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            textAlign: 'center',
        }}
    >
        {props.children}
    </div>

)
