import React, { Component } from 'react';
import ExpansionList from './ExpansionList';
import Demands from '../config/demands.json';
import Column from './Column';
import rainer from '../images/rainer.jpg';
import hoh from '../images/hoh.jpg';


export default class Home extends Component {
  render() {
      return (
        <Column>
          <ExpansionList
            content={Demands.slice(0, 1)}
            imgSrc={rainer}
            title={'WE DEMAND:'}
            enumerate
          />
          <ExpansionList
            content={Demands.slice(1, 2)}
            imgSrc={hoh}
            enumerate
            indexOffset={1}
          />
          <ExpansionList
            content={Demands.slice(2, 3)}
            imgSrc={rainer}
            enumerate
            indexOffset={2}
          />
          <ExpansionList
            content={Demands.slice(3, 4)}
            imgSrc={hoh}
            enumerate
            indexOffset={3}
          />
        </Column>
    );
  }
}
