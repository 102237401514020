import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default (props) => (

    <Button color={'secondary'} variant={'contained'}>
        <Typography
          variant="h6"
          color="inherit"
          style={{
            fontFamily:'fucked',
            display:'inline-flex',
            alignItems:'center'
          }}>
            {props.children}
        </Typography>
    </Button>
)
