import React, { Component } from 'react';
import Column from './Column';
import skyline from '../images/skyline.jpg'

export default class extends Component {

    componentDidMount() {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://actionnetwork.org/widgets/v3/form/xr-seattle-sign-up-2?format=js&source=widget";
        document.body.appendChild(script);
    }

    render() {
        return (
            <div
                style={{
                    minHeight: 'calc(100vh - 64px)',
                    backgroundImage: `url(${skyline})`,
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
            <Column>
                <div style={{
                    textAlign:'center',
                }}>
                    <div style={{
                        fontFamily: 'fucked',
                        textAlign:'center',
                        fontSize:'30px',
                        marginTop: '30px',
                        marginBottom: '30px',
                        textTransform: 'uppercase'
                    }}>
                        Contact us to learn how you can participate
                    </div>

                    <link href='https://actionnetwork.org/css/style-embed-whitelabel-v3.css'
                      rel='stylesheet'
                      type='text/css' />
                    <div
                        id='can-form-area-xr-seattle-sign-up-2'
                        style={{
                            width: '94%',
                            backgroundColor: 'rgba(255, 255, 255, 0.85)',
                            padding: '20px 3%',
                            marginBottom: '20px',
                            fontFamily: 'fucked'
                        }} />
                </div>
            </Column>
            </div>
        )
    }
}
