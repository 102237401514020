import React, { Component } from 'react';

import './App.css';

import Home from './components/Home';
import WhoWeAre from './components/WhoWeAre';
import Demands from './components/Demands';
import Principles from './components/Principles';
import AppBar from './components/AppBar';
//import Column from './components/Column'

import { HashRouter as Router, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import GetInvolved from './components/GetInvolved';
//import ExpansionList from './components/ExpansionList';

// import createTypography from 'material-ui/styles/typography';
// import createPalette from 'material-ui/styles/palette';

//import purple from '@material-ui/core/colors/purple';
//import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00ae18',
    },
    secondary: {
      main: '#ffc14d',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'crimson_regular'
    ].join(','),
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>

        <Router>
          <>
            <AppBar />
            <div
              style={{
                marginTop:'65px',
                height: '100%'
              }}
            >
              <Route path="/" exact component={Home} />
              <Route path="/demands/" component={Demands} />
              <Route path="/principles" component={Principles} />
              <Route path="/whoweare/" component={WhoWeAre} />
              <Route path="/getinvolved/" component={GetInvolved} />
            </div>
          </>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
