import React, { Component } from 'react';
import ExpansionList from './ExpansionList';
//import Column from './Column';

import Principles from '../config/principles.json';
import rainer from '../images/rainer.jpg';
import hoh from '../images/hoh.jpg';
//import skyline from '../images/skyline.jpg';


export default class Home extends Component {

  randomImage = () => {
    const images = [hoh, rainer];
    return images[Math.round(Math.random()*(images.length-1))]
  }

  render() {
      return (
        <ExpansionList
          // expand
          content={Principles}
          imgSrc={hoh}
          title={'PRINCIPLES'}
          contrast
          style={{
            textAlign: 'center',
          }}
          // indexOffset={2}
        />
    );
  }
}
