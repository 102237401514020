import React from 'react';
import facebook from '../icons/facebook.svg';
import instagram from '../icons/instagram.svg';
import twitter from '../icons/twitter.svg';


// const sources = [
//     facebook,
//     instagram,
//     twitter,
// ]

const sources = [
    {
        href: 'https://www.facebook.com/XRSeattle/',
        src: facebook,
    },
    {
        href: 'https://www.instagram.com/extinctionrebellionseattle/',
        src: instagram,
    },
    {
        href: 'https://twitter.com/xrseattle',
        src: twitter
    }
]

export default () => (
    <>
        {sources.map((x, index) => {
            return (
                    <div key={index}>
                    <a
                        href={x.href}
                        style={{
                            textAlign:'center',
                            width:'100%',
                            display:'inline-flex',
                            alignItems:'center',
                            justifyContent:'center',
                        }}
                        target={'blank'}
                    >
                        <img
                            alt="socialicon"
                            style={{
                                margin: 10,
                                width: 40,
                                height: 40,
                            }}
                            src={x.src}
                        />
                    </a>
                    <br/>
                    </div>
            )
        })}
    </>

)
