import React from 'react';

export default (props) => (
    <div
        style={{
            backgroundColor: 'rgb(50, 50, 50)',
            width:'100%',
            borderRadius: '5px',
            fontSize:'20px',
            color:'white',
            textAlign: 'left',
            padding:'20px',
        }}
    >
        {props.children}
    </div>
)